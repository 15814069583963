import { IconButton, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/Iconify";

const Password = ({ name, label, onVisibilityToggle, ...other }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleVisibility = () => {
    setShowPassword((prev) => !prev);
    if (onVisibilityToggle) {
      onVisibilityToggle(!showPassword); // Call the prop function if provided
    }
  };

  return (
    <RHFTextField
      name={name}
      label={label}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={handleToggleVisibility}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              <Iconify
                icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};

Password.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onVisibilityToggle: PropTypes.func, // Optional callback for visibility toggle
};

export default Password;
