// LoginForm.js

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Password from "src/components/Password";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import ErrorBanner from "./components/errorBanner";
import useLogin from "./hooks/useLogin";
import { useTheme } from "@emotion/react";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState, useCallback } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import axiosInstance from "src/utils/axios";
import Iconify from "src/components/Iconify";
import { setSession } from "src/utils/jwt"; // Import is correct, but not needed here
import useLocales from "src/hooks/useLocales";

const LoginForm = () => {
  const { palette } = useTheme();
  const { login, getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // Validation schema using Yup
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$|^[a-z0-9]+$/,
        "Email or username must be lowercase and valid"
      )
      .required("Email or Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const [tfaError, setTfaError] = useState(null);
  const [openOtp, setOpenOtp] = useState(false);
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState(null);
  const { translate } = useLocales();

  const handleClose = () => {
    setOpenOtp(false);
    setOtp(null);
    setTfaError(null);
  };

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // Ref to track if the component is mounted
  const isMountedRef = useRef(true);

  useEffect(() => {
    // Set ref to true when mounted
    isMountedRef.current = true;
    return () => {
      // Cleanup: set ref to false when unmounted
      isMountedRef.current = false;
    };
  }, []);

  const onSubmit = async (data) => {
    const {
      status,
      data: res,
      message,
      secret: responseSecret,
    } = await login(data.email, data.password, data.remember);

    if (!isMountedRef.current) return; // Exit if unmounted

    if (status) {
      await getUser();
      if (!isMountedRef.current) return; // Exit if unmounted after getUser
      enqueueSnackbar(`Welcome ${res}`, { variant: "success" });
      reset();
      navigate("/");
    } else if (responseSecret) {
      if (!isMountedRef.current) return; // Exit if unmounted
      setSecret(responseSecret);
      setOpenOtp(true);
    } else {
      if (!isMountedRef.current) return; // Exit if unmounted
      setError("afterSubmit", { message });
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (otp === "") {
      setTfaError(null);
    }
  }, [otp]);

  const TfaLogin = async () => {
    const reqData = new FormData();
    const [email, password] = getValues(["email", "password"]);
    reqData.append("email", email);
    reqData.append("password", password);
    reqData.append("key", secret);
    reqData.append("code", otp);
    reqData.append("verify", 1);
    reqData.append("secret", secret);

    try {
      const { data, status } = await axiosInstance.post(
        "api/twofaverify",
        reqData
      );
      if (status === 200 && isMountedRef.current) {
        localStorage.setItem("isAdmin", Boolean(data.user.is_super_admin));
        localStorage.setItem("isSubAdmin", Boolean(data.user.is_sub_admin));
        setSession(data.access_token);
        navigate(0);
      }
    } catch (err) {
      if (isMountedRef.current) {
        enqueueSnackbar(
          err?.response?.data?.message || "2FA Verification failed",
          { variant: "error" }
        );
        if (err.response?.data?.errors) {
          const errorMessages = Object.values(err.response.data.errors).flat();
          setTfaError(errorMessages.join(" "));
        } else {
          setTfaError("Invalid OTP code.");
        }
      }
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <AuthHelper />
          <ErrorBanner />
        </Stack>

        <Box>
          <Stack spacing={2}>
            <RHFTextField
              name="email"
              label={translate("email_username")}
              onKeyDown={(e) => {
                if (e.key === " " && !e.target.value.length) {
                  e.preventDefault();
                }
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toLowerCase();
              }}
            />
            <Password
              name="password"
              label={translate("password")}
              InputLabelProps={{ style: { color: "#b4c1ce" } }}
              onVisibilityToggle={() => setShowPassword((prev) => !prev)}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label={translate("remember")} />
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATH_AUTH.resetPassword}
            >
              {translate("forgotten_password")}
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            name="login"
          >
            {translate("login")}
          </LoadingButton>
        </Box>
      </FormProvider>

      <Dialog open={openOtp} onClose={handleClose}>
        <DialogContent>
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon="carbon:close-filled" />
            </IconButton>
          </Box>
          <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
            {translate("2fa")}
          </Typography>
          <Stack spacing={3}>
            <TextField
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              label="Enter OTP"
              error={Boolean(tfaError)}
              helperText={tfaError}
              fullWidth
            />
            <Button
              variant="contained"
              onClick={TfaLogin}
              disabled={!otp}
              fullWidth
            >
              {translate("submit")}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginForm;
