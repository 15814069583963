// src/context.js

import React, { createContext, useContext, useMemo, useState, useCallback } from "react";

const initialAppState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  tourType: null, // "admin" or "user"
};

export const AppContext = createContext({
  state: initialAppState,
  setState: () => {},
});

export function AppProvider({ children }) {
  const [state, setInternalState] = useState(initialAppState);

  const setState = useCallback((patch) => {
    setInternalState((prev) => {
      if (typeof patch === "function") {
        return { ...prev, ...patch(prev) };
      }
      return { ...prev, ...patch };
    });
  }, []);

  const value = useMemo(() => ({ state, setState }), [state, setState]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
}
