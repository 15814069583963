// src/joyride/steps-user.js
import React from "react";
import StepContent from "./StepContent"; // Ensure correct import path

/**
 * Generates Joyride steps for the /user/dashboard and related user-specific pages.
 * @param {Function} translate - Function for i18n/localization.
 * @returns {Array} Array of Joyride step objects.
 */
const getUserSteps = (translate) => [
  {
    target: ".referral-widget-step",
    content: (
      <StepContent
        title={translate("Referral Widget") || "Referral Widget"}
        description={
          translate("This widget displays your referral link and current package.") ||
          "This widget displays your referral link and current package."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard", // Add the route
  },
  {
    target: ".active-subscription-list-step",
    content: (
      <StepContent
        title={translate("Active Subscriptions") || "Active Subscriptions"}
        description={
          translate("View all your active subscriptions here, along with their details.") ||
          "View all your active subscriptions here, along with their details."
        }
      />
    ),
    disableBeacon: true,
    placement: "right",
    route: "/user/dashboard",
  },
  {
    target: ".active-user-widget-step",
    content: (
      <StepContent
        title={translate("Active Users") || "Active Users"}
        description={
          translate("This widget shows the number of active users currently engaged with your platform.") ||
          "This widget shows the number of active users currently engaged with your platform."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".downline_count-widget-step",
    content: (
      <StepContent
        title={translate("Downline Count") || "Downline Count"}
        description={
          translate("Track your downline-count here.") ||
          "Track your downline-count here."
        }
      />
    ),
    disableBeacon: true,
    placement: "left",
    route: "/user/dashboard",
  },
  {
    target: ".income-widget-step",
    content: (
      <StepContent
        title={translate("Income") || "Income"}
        description={
          translate("Track your income here.") || "Track your income here."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".balance-widget-step",
    content: (
      <StepContent
        title={translate("Balance") || "Balance"}
        description={
          translate("View your current balance.") || "View your current balance."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".total_payout-widget-step",
    content: (
      <StepContent
        title={translate("Total Payout") || "Total Payout"}
        description={
          translate("Monitor your total payouts here.") ||
          "Monitor your total payouts here."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".pending_payout-widget-step",
    content: (
      <StepContent
        title={translate("Pending Payout") || "Pending Payout"}
        description={
          translate("Check your pending payouts in this widget.") ||
          "Check your pending payouts in this widget."
        }
      />
      ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".total_referrals-widget-step",
    content: (
      <StepContent
        title={translate("Total Referrals") || "Total Referrals"}
        description={translate("Your referrals count") || "Your referrals count."}
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".total_fund_credited-widget-step",
    content: (
      <StepContent
        title={translate("Total Fund Credited") || "Total Fund Credited"}
        description={
          translate("View the total funds credited to your account.") ||
          "View the total funds credited to your account."
        }
      />
      ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".my_subscriptions-widget-step",
    content: (
      <StepContent
        title={
          translate("Your subscriptions count") || "Your subscriptions count"
        }
        description={
          translate("View your subscriptions count") ||
          "View your subscriptions count."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".investment-widget-step",
    content: (
      <StepContent
        title={translate("Investment") || "Investment"}
        description={
          translate("Manage your investments here.") ||
          "Manage your investments here."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  },
  {
    target: ".dashboard-calendar-step",
    content: (
      <StepContent
        title={translate("Event Calendar") || "Event Calendar"}
        description={
          translate("Manage and view all your upcoming events in this calendar.") ||
          "Manage and view all your upcoming events in this calendar."
        }
      />
    ),
    disableBeacon: true,
    placement: "left",
    route: "/user/dashboard/events",
  },
  {
    target: ".product-list-step",
    content: (
      <StepContent
        title={translate("Product List") || "Product List"}
        description={
          translate("Browse through all your products and manage their details here.") ||
          "Browse through all your products and manage their details here."
        }
      />
      ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/dashboard",
  }, 
  {
    target: ".package-grid-step",
    content: (
      <StepContent
        title={translate("Investment Packages") || "Investment Packages"}
        description={
          translate("Browse through all Investment Packages") ||
          "Browse through all Investment Packages."
        }
      />
      ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/invest",
  },{
    target: ".tour-button-step",
    content: (
      <StepContent
        title={translate("Tour Complete") || "Tour Complete"}
        description={
          translate("Tour Complete") ||
          "Tour Complete! You can retake the tour anytime by clicking here!"
        }
      />
      ),
    disableBeacon: true,
    placement: "center",
    route: "/user/invest",
  },
  {
    target: ".package-grid-stepx",
    content: (
      <StepContent
        title={translate("Investment Packages") || "Investment Packages"}
        description={
          translate("Browse through all Investment Packages") ||
          "Browse through all Investment Packages."
        }
      />
      ),
    disableBeacon: true,
    placement: "bottom",
    route: "/user/invest",
  },
];

export default getUserSteps;
