export default function LoadingButton() {
  return {
    MuiLoadingButton: {
      defaultProps: {
        translate: "no", // Prevent translation for the entire component by default
      },
      styleOverrides: {
        root: {
          "& .MuiButton-text": {
            "& .MuiLoadingButton-startIconPendingStart": {
              marginLeft: 0, // Keep styles consistent
            },
            "& .MuiLoadingButton-endIconPendingEnd": {
              marginRight: 0, // Keep styles consistent
            },
            "& .MuiButton-label": {
              translate: "yes", // Allow translation only for the label (inner text)
            },
          },
        },
      },
    },
  };
}
