import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import EmptyContent from "src/components/EmptyContent";
import Iconify from "../Iconify";
import Ternary from "../ternary";
import useLocales from "src/hooks/useLocales";
import EmptyMail from "src/pages/admin/communications/Mail/subPages/MailList/components/emptyMail";

const EmptyTable = ({ error = false, isMail }) => {
  const { palette } = useTheme();
  const { translate } = useLocales();

  return (
    <Box>
      <Ternary
        when={error}
        then={
          <Box sx={{ p: 4 }}>
            <Box sx={{ justifyContent: "center", display: "flex", mt: 4, mb: 2 }}>
              <Box
                style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "16px",
                  backgroundColor: "#ffeeef",
                  textAlign: "center",
                  display: "flex",
                  margin: "15px auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon="clarity:info-solid"
                  style={{ color: "#ff9595", fontSize: "45px" }}
                />
              </Box>
            </Box>
            <Typography variant="h3" sx={{ color: "#ff6767", textAlign: "center" }}>
              Oops !!!
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", color: palette.text.secondary }}
            >
              Something went wrong. Please try again later.
            </Typography>
          </Box>
        }
        otherwise={
          <Ternary
            when={isMail}
            then={<EmptyMail />}
            otherwise={
              <EmptyContent
                title={translate("noDataAvailable")}
                sx={{
                  "& span.MuiBox-root": { height: 160 },
                  color: palette.text.disabled,
                }}
              />
            }
          />
        }
      />
    </Box>
  );
};

EmptyTable.propTypes = {
  error: PropTypes.bool,
  isMail: PropTypes.bool,
};

export default EmptyTable;
