// src/App.js
import React, { useEffect,useState } from "react";
import { AppProvider } from "./context";
import TourProvider from "./joyride/TourProvider";
import Router from "./routes";
import NotistackProvider from "./components/NotistackProvider";
import ThemeProvider from "./theme";
import ThemeColorPresets from "./components/ThemeColorPresets";
import RtlLayout from "./components/RtlLayout";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import ProgressBarStyle from "./components/ProgressBar";
import { ChartStyle } from "./components/chart";
import Settings from "./components/settings";
import ScrollToTop from "./components/ScrollToTop";
import Ternary from "./components/ternary";
import Visitor from "./components/visitor";
// import IsActive from "./components/non-active/index";
import axiosInstance from "./utils/axios";
import ReactGA from "react-ga4";
import useAuth from "./hooks/useAuth";
import useMenu from "./hooks/useMenu";
import { ENABLE_VISITOR_MODE } from "./config";

const fetchBrandSettings = async () => {
  try {
    const { data, status } = await axiosInstance("api/company-logos");
    if (status === 200) {
      const { logo, side_bar_logo, favicon } = data.data;
      if (logo) {
        localStorage.setItem("logo", logo);
        localStorage.setItem("side_bar_logo", side_bar_logo);
        localStorage.setItem("favicon", favicon);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export default function App() {
  useMenu();
  const { user, isAuthenticated, getUser } = useAuth();

  useEffect(() => {
    ReactGA.initialize("G-LZMEKWKMP2");
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    if (isAuthenticated && user === null) {
      getUser();
    }
  }, [isAuthenticated, user, getUser]);

  useEffect(() => {
    fetchBrandSettings();
  }, []);
  const [openSettings, setOpenSettings] = useState(false);

  const handleOpen = () => setOpenSettings(true);
  const handleClose = () => setOpenSettings(false);

  return (
    <AppProvider>
      <ThemeProvider>
        <ThemeColorPresets>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings open={openSettings} handleClose={handleClose} />
                <ScrollToTop />
                <Router />
                <TourProvider />
                <Ternary when={ENABLE_VISITOR_MODE} then={<Visitor />} />
                {/* <IsActive /> */}
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeColorPresets>
      </ThemeProvider>
    </AppProvider>
  );
}
