import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import DataHandlerTable from "src/components/data-handler/table";
import EmptyTable from "src/components/emptyTable";
import Map from "src/components/map";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import Ternary from "src/components/ternary";
import useLocales from "src/hooks/useLocales";
import { checkIsWhite } from "src/pages/admin/financial/ewallet/components/dataTable";
import { BodyRow } from "src/pages/admin/reports/components";
import { filterType } from "src/pages/admin/reports/income/dataList";
import { formatAmount } from "src/pages/user/incomeReport/components/row";

const headers = [
  "userFinancial.eWallet.no",
  "userFinancial.eWallet.fromUser",
  "userFinancial.eWallet.amountType",
  "Package Name",
  "Daily Profit (%)",
  "userFinancial.depositWallet.paymentType",
  "userFinancial.eWallet.amount",
  "userFinancial.eWallet.date",
];

const DataList = ({ state, rowStart, ...rest }) => {
  const { data, ...dataProps } = state;
  const { translate } = useLocales();
  const isEmpty = !Boolean(data?.length);

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable
            name="faq-table"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(item, i) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item.from_user?.username}</TableCell>
                    <TableCell>{filterType(item.payment_type)}</TableCell>
                    <TableCell>
                      {capitalCase(item.package?.name || "")}
                    </TableCell>

                    <TableCell style={{ color: "#00c59e" }}>
                      {item?.daily_profit_percentage}
                    </TableCell>

                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {item.type}
                    </TableCell>
                    <TableCell>
                      <Ternary
                        when={snakeCase(item.payment_type) === "fund_transfer"}
                        then={<span>{formatAmount(item.payable_amount)} </span>}
                        otherwise={
                          <Ternary
                            when={item.type === "debit"}
                            then={
                              <>
                                <Ternary
                                  when={checkIsWhite(
                                    snakeCase(item.payment_type)
                                  )}
                                  then={
                                    <span style={{ color: "white" }}>
                                      {formatAmount(item.payable_amount)}
                                    </span>
                                  }
                                  otherwise={
                                    <span style={{ color: "red" }}>
                                      -{formatAmount(item.payable_amount)}
                                    </span>
                                  }
                                />
                              </>
                            }
                            otherwise={
                              <>
                                <Ternary
                                  when={checkIsWhite(
                                    snakeCase(item.payment_type)
                                  )}
                                  then={
                                    <span style={{ color: "white" }}>
                                      {formatAmount(item.payable_amount)}
                                    </span>
                                  }
                                  otherwise={
                                    <span style={{ color: "#00c59e" }}>
                                      +{formatAmount(item.payable_amount)}{" "}
                                    </span>
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(item.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                </>
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
