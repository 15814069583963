import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();

  return (
    smUp && (
      <Typography variant="body2" sx={{ mt: { md: -2 } }}>
        <LanguagePopover/>
        {translate("login_account")} &nbsp;? &nbsp;&nbsp;
        <Link
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          {translate("get_started")}
        </Link>
      </Typography>
    )
  );
};

export default GetStartedTitle;
