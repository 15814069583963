import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";

import { FormControl } from "@mui/material";

import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useLocales from "src/hooks/useLocales";
import useGetSubChapter from "src/pages/admin/course/sub-chapter/hook/useGetSubChapter";

const Form = ({ methods, onSubmit, onClose }) => {
  const { translate } = useLocales();
  const {
    formState: { errors },
    watch,
  } = methods;
  const { state } = useGetSubChapter();
  const { data } = state;
  const isSubChapterId = Boolean(watch("sub_chapter_id"));
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <DialogContent>
        <DialogContentText>
          <Box mt={3}>
            <FormControl fullWidth>
              <RHFSelect
                InputLabelProps={{
                  shrink: isSubChapterId ? true : false,
                }}
                name="sub_chapter_id"
                label={translate("subChapter")}
              >
                <option value="" />
                {data.map(({ id, name }) => (
                  <option value={id}> {name}</option>
                ))}
              </RHFSelect>
            </FormControl>
          </Box>

          <Box mt={3}>
            <FormControl fullWidth>
              <TextField
                type="file"
                inputProps={{ accept: ".xlsx, .xls, .pdf" }}
                {...methods.register("document_url")}
                label={translate("adminTools.documents.uploadDocument")}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.document_url)}
                helperText={errors.document_url?.message}
                name="document_url"
              />
            </FormControl>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={methods.formState.isSubmitting}
          type="submit"
          variant="contained"
          autoFocus
          name="submit"
        >
          {translate("adminTools.documents.submit")}
        </LoadingButton>
        <Button onClick={onClose} autoFocus color="error" name="close">
          {translate("adminTools.documents.close")}
        </Button>
      </DialogActions>
    </FormProvider>
  );
};

export default Form;
