import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { snakeCase } from "change-case";
import Ternary from "src/components/ternary";
import { filterType } from "src/pages/admin/reports/income/dataList";

// Helper function to format the amount
export const formatAmount = (amount) => {
  const num = parseFloat(amount);
  if (isNaN(num)) return "0$";

  // Round to two decimal places
  let formatted = num.toFixed(2);

  // Remove trailing zeros and decimal point if not needed
  if (formatted.endsWith(".00")) {
    formatted = parseInt(formatted, 10).toString();
  } else if (formatted.endsWith("0")) {
    formatted = formatted.slice(0, -1);
  }

  return `${formatted}$`;
};

const Row = ({ income, data, rowNumber }) => {
  const { id, fromuser, payment_type, total_amount, created_at } = income;

  return (
    <TableRow key={id}>
      <TableCell>{rowNumber}</TableCell>
      <TableCell>{fromuser?.username || "-"}</TableCell>
      <TableCell>{filterType(payment_type)}</TableCell>
      <TableCell style={{ color: "#00c59e" }}>
        <Ternary
          when={snakeCase(payment_type) === "daily_bonus"}
          then={<span>+{formatAmount(total_amount)}</span>}
          otherwise={<span>+{formatAmount(total_amount)}</span>}
        />
      </TableCell>
      <TableCell>{new Date(created_at).toLocaleDateString("en-GB")}</TableCell>
    </TableRow>
  );
};

export default Row;
