// contexts/JWTContext.js
import React, { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import axiosInstance from "../utils/axios"; // Custom Axios instance
import { isValidToken, setSession } from "../utils/jwt"; // JWT utilities

// Initial authentication state
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  isSubAdmin: false,
  user: null,
};

// Handlers for different action types
const handlers = {
  RESET_CART: (state) => ({
    ...state,
    user: { ...state.user, cartCount: 0 },
  }),
  INCREMENT_CART: (state, { payload }) => ({
    ...state,
    user: { ...state.user, cartCount: payload },
  }),
  DECREMENT_CART: (state, { payload }) => ({
    ...state,
    user: { ...state.user, cartCount: state.user.cartCount - payload },
  }),
  SET_PROFILE_IMAGE: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      user_profile: {
        ...state.user?.user_profile,
        profile_image: payload,
      },
    },
  }),
  SET_IMAGE: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      user_profile: {
        ...state.user?.user_profile,
        cover_image: payload,
      },
    },
  }),
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false,
      isSubAdmin: JSON.parse(localStorage.getItem("isSubAdmin")) || false,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isAdmin: Boolean(user.is_super_admin),
      isSubAdmin: Boolean(user.is_sub_admin),
      user,
    };
  },
  GET_USER: (state, action) => {
    const {
      scope_email,
      scope_facebook,
      scope_instagram,
      scope_medium,
      scope_phone,
      scope_telegram,
      scope_twitter,
      scope_whatsapp,
      referral_count,
      ...rest
    } = action.payload.user;

    return {
      ...state,
      isAuthenticated: true,
      isAdmin: action.payload.isAdmin,
      isSubAdmin: action.payload.isSubAdmin,
      user: {
        ...rest,
        social: {
          scope_email,
          scope_facebook,
          scope_instagram,
          scope_medium,
          scope_phone,
          scope_telegram,
          scope_twitter,
          scope_whatsapp,
        },
      },
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isAdmin: false,
    isSubAdmin: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

// Reducer function to handle state changes
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create AuthContext with default values and placeholder functions
const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  getUser: () => Promise.resolve(),
  setImage: () => Promise.resolve(),
  setProfileImage: () => Promise.resolve(),
  incrementCart: () => Promise.resolve(),
  decrementCart: () => Promise.resolve(),
  resetCart: () => Promise.resolve(),
});

// Define PropTypes for AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node,
};

// AuthProvider component to wrap around your app
function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Initialization effect to check for existing token and fetch user data
  useEffect(() => {
    const initialize = async () => {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken || !isValidToken(accessToken)) {
        // If token is missing or invalid, ensure it's removed
        setSession(null);
        dispatch({
          type: "INITIALIZE",
          payload: { isAuthenticated: false, user: null },
        });
        return;
      }

      // If token is valid, set it in session (localStorage already has it)
      setSession(accessToken);

      try {
        // Fetch user profile to validate token and get user data
        const { data, status } = await axiosInstance.get("/api/profile");

        if (status === 200) {
          const {
            user,
            cart_count,
            referral_count,
            balance_amount,
            total_commission,
            total_payout,
            sponsor_info,
            user_package_status,
          } = data.data;

          const sponsorInfo = {
            name: sponsor_info?.user_profile?.first_name,
            username: sponsor_info?.username,
            country: sponsor_info?.user_profile?.country,
            joinedAt:
              sponsor_info?.created_at &&
              new Date(sponsor_info?.created_at).toLocaleDateString("en-GB"),
          };

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                cartCount: cart_count,
                referralCount: referral_count,
                balanceAmount: parseFloat(balance_amount, 10),
                totalCommission: parseFloat(total_commission, 10),
                totalPayout: parseFloat(total_payout, 10),
                sponsorInfo,
                userPackageStatus: user_package_status,
                sponsor_info: sponsor_info,
              },
            },
          });
        } else {
          // If status is not 200, treat as unauthenticated
          setSession(null);
          dispatch({
            type: "INITIALIZE",
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (error) {
        // If there's an error (e.g., 401), remove token and set unauthenticated
        setSession(null);
        dispatch({
          type: "INITIALIZE",
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initialize();
  }, []);

  // Login function to authenticate the user
  const login = async (email, password, remember) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("remember", remember);

    try {
      // Use the standard Axios instance here since token is not yet set
      const URL = `${
        process.env.REACT_APP_HOST_NAME || "http://127.0.0.1:8000/"
      }api/login`;
      const { status, data } = await axios.post(URL, formData);

      if (status === 200) {
        const { access_token, user, message, secret } = data;

        if (!secret) {
          if (data.status === false) {
            // If backend indicates failure
            return { status: false, message };
          }

          // Store admin flags in localStorage
          localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
          localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));

          // Set the session with the new token
          setSession(access_token);

          // Dispatch login action to update state
          dispatch({
            type: "LOGIN",
            payload: { user },
          });

          return { status: true, data: user.username };
        } else if (secret) {
          // Handle cases where additional verification is needed (e.g., 2FA)
          return { status: false, secret };
        } else {
          return { status: false, message };
        }
      }
    } catch (err) {
      // Handle login errors (e.g., incorrect credentials)
      return {
        status: false,
        message: err?.response?.data?.message || "Login failed",
      };
    }

    return { status: false, message: "Login: unknown error" };
  };

  // Register function to create a new user
  const register = async (email, username, password, referral) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("referral", referral);

    try {
      const { status, data } = await axiosInstance.post(
        "api/register",
        formData
      );
      if (status === 200) {
        return { status, data };
      }
      return { status: false, data: null };
    } catch (error) {
      // Handle registration errors
      console.log(error);

      return {
        status: false,
        data: error,
      };
    }
  };

  // Logout function to sign out the user
  const logout = async () => {
    try {
      const { status } = await axiosInstance.post("api/logout");
      if (status === 200) {
        // Clear session and relevant localStorage items
        setSession(null);
        localStorage.removeItem("menu");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("isSubAdmin");
        localStorage.removeItem("isImpersonate");
        localStorage.removeItem("source_id");

        // Dispatch logout action to update state
        dispatch({ type: "LOGOUT" });
      }
    } catch (err) {
      console.error("Logout failed:", err);
      // Optionally, handle logout errors here
    }
  };

  // Reset password function
  const resetPassword = async (reqData) => {
    const form = new FormData();
    form.append("email", reqData.email);
    form.append("password", reqData.password);
    form.append("password_confirmation", reqData.repassword);
    form.append("token", reqData.token);

    try {
      const res = await axiosInstance.post("/reset-password", form);
      return res.status === 200;
    } catch (error) {
      console.error("Reset password failed:", error);
      return false;
    }
  };

  // Function to fetch and update user data
  const getUser = async () => {
    try {
      const { status, data } = await axiosInstance.get("api/profile");
      if (status === 200) {
        const {
          user,
          cart_count,
          referral_count,
          balance_amount,
          total_commission,
          total_payout,
          sponsor_info,
          user_package_status,
        } = data.data;

        const sponsorInfo = {
          name: sponsor_info?.user_profile?.first_name,
          username: sponsor_info?.username,
          country: sponsor_info?.user_profile?.country,
          joinedAt:
            sponsor_info?.created_at &&
            new Date(sponsor_info.created_at).toLocaleDateString("en-GB"),
        };

        if (user) {
          dispatch({
            type: "GET_USER",
            payload: {
              user: {
                ...user,
                cartCount: cart_count,
                referralCount: referral_count,
                balanceAmount: parseFloat(balance_amount, 10),
                totalCommission: parseFloat(total_commission, 10),
                totalPayout: parseFloat(total_payout, 10),
                sponsorInfo,
                userPackageStatus: user_package_status,
                sponsor_info: sponsor_info,
              },
              isSubAdmin:
                JSON.parse(localStorage.getItem("isSubAdmin")) || false,
              isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false,
            },
          });
        }
      }
    } catch (err) {
      console.error("Fetching user failed:", err);
      // Optionally, handle errors (e.g., show a notification)
    }
  };

  // Additional helper functions to manage user profile images and cart
  const setImage = async (image) => {
    dispatch({ type: "SET_IMAGE", payload: image });
  };

  const setProfileImage = (newUri) => {
    dispatch({ type: "SET_PROFILE_IMAGE", payload: newUri });
  };

  const incrementCart = (cartCount) => {
    dispatch({ type: "INCREMENT_CART", payload: cartCount });
  };

  const decrementCart = () => {
    dispatch({ type: "DECREMENT_CART", payload: 1 });
  };

  const resetCart = () => {
    dispatch({ type: "RESET_CART" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        resetPassword,
        getUser,
        setImage,
        setProfileImage,
        incrementCart,
        decrementCart,
        resetCart,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
