// utils/jwt.js
import jwtDecode from "jwt-decode";

// Validate the JWT token by checking its expiration
export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  try {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp > currentTime;
  } catch (error) {
    // Token is invalid or malformed
    return false;
  }
};

// Manage the JWT token in localStorage
export const setSession = (accessToken) => {
  if (accessToken) {
    // Save the token to localStorage
    localStorage.setItem("accessToken", accessToken);
  } else {
    // Remove the token from localStorage
    localStorage.removeItem("accessToken");
  }
};
