// src/joyride/StepContent.jsx
import React from "react";
import { Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const containerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

export default function StepContent({ title, description }) {
  return (
    <div>
      <div style={containerStyle}>
        <InfoIcon color="primary" />
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </div>
      <Typography variant="body1">{description}</Typography>
    </div>
  );
}
