import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import useErrors from "src/hooks/useErrors";
import useLocales from "src/hooks/useLocales";
import fetchUser from "src/utils/fetchUser";
import { number, object } from "yup";

const genSchema = (limit) => {
  const schema = object().shape({
    amount: number()
      .typeError("Amount is required")
      .min(1, "The amount should be at least 1")
      .max(
        limit,
        limit <= 0
          ? "You don't have enough balance"
          : `The amount should be less than ${limit}`
      )
      .required("Amount is required"),
  });

  return schema;
};

const PayNow = ({ balance, fetchData }) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const handleErrors = useErrors();

  const methods = useForm({
    defaultValues: { amount: "" },
    resolver: yupResolver(genSchema(balance)),
  });

  const onSubmit = async (inputData) => {
    const reqData = new FormData();
    reqData.append("amount", inputData.amount);

    try {
      const { message, status } = await (
        await fetchUser.post("ewallet_to_deposit", reqData)
      ).data;

      if (status) {
        enqueueSnackbar(message);
        methods.reset({ amount: "" });
        fetchData();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
      handleErrors(err);
    }
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box>
          <FormProvider
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(4, 1fr)",
                },
              }}
            >
              <RHFTextField
                type="number"
                name="amount"
                label={translate("userFinancial.depositWallet.amount")}
                size="small"
              />
              <Box sx={{ display: "flex" }}>
                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  variant="contained"
                  type="submit"
                  name="add-credit"
                >
                  {translate("userFinancial.depositWallet.addCredit")}
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </>
  );
};

export default PayNow;
