import { Box, Stack, Typography } from "@mui/material";
import { DOMAIN_NAME } from "src/config";
import useLocales from "src/hooks/useLocales";

const Message = () => {
  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4" gutterBottom>
          {translate("signin")} {DOMAIN_NAME}
        </Typography>
        {/* <Typography sx={{ color: "text.secondary" }}>
        Lorem Ipsum is simply dummy...
      </Typography> */}
      </Box>
    </Stack>
  );
};

export default Message;
