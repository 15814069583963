import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import EmptyTable from "../emptyTable";
import Loop from "../loop";
import Ternary from "../ternary";

const DataHandlerTable = ({
  dataProps,
  headers = [],
  children,
  tableProps = {},
  name,
}) => {
  const { translate } = useLocales();
  const { loading, error, isArrayEmpty } = dataProps;

  return (
    <TableContainer component={Paper}>
      <Table {...tableProps} name={name}>
        <TableHead>
          <TableRow>
            <Loop
              list={headers}
              render={(item, index) => (
                <TableCell key={index}>{translate(item)}</TableCell>
              )}
            />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      <Ternary
        when={loading}
        then={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <CircularProgress />
          </Box>
        }
        otherwise={
          <Ternary when={isArrayEmpty} then={<EmptyTable error={error} />} />
        }
      />
    </TableContainer>
  );
};

export default DataHandlerTable;
