// src/joyride/steps-admin.js
import React from "react";
import StepContent from "./StepContent"; // Ensure correct import path

/**
 * Generates Joyride steps for the Admin dashboard or admin pages.
 * @param {Function} translate - For i18n/localization.
 * @returns {Array} Array of Joyride step objects.
 */
const getAdminSteps = (translate) => [
  {
    target: ".balance-widget-step",
    content: (
      <StepContent
        title={translate("Balance Widget") || "Balance Widget"}
        description={
          translate("This widget shows your current balance.") ||
          "This widget shows your current balance."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business", // Add the route
  },
  {
    target: ".network-bonus-step",
    content: (
      <StepContent
        title={translate("Network Bonus") || "Network Bonus"}
        description={
          translate("Displays the network bonus for your entire structure.") ||
          "Displays the network bonus for your entire structure."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business",
  },
  {
    target: ".total-sales-step",
    content: (
      <StepContent
        title={translate("Total Sales") || "Total Sales"}
        description={
          translate("Shows total sales to date.") ||
          "Shows total sales to date."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business",
  },
  {
    target: ".total-expense-step",
    content: (
      <StepContent
        title={translate("Total Expense") || "Total Expense"}
        description={
          translate("View total expenses for your business.") ||
          "View total expenses for your business."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business",
  },
  {
    target: ".total-profit-step",
    content: (
      <StepContent
        title={translate("Total Profit") || "Total Profit"}
        description={
          translate("See your net profit after expenses.") ||
          "See your net profit after expenses."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business",
  },
  {
    target: ".customers-widget-step",
    content: (
      <StepContent
        title={translate("Customers Widget") || "Customers Widget"}
        description={
          translate("Quick overview of new or top customers.") ||
          "Quick overview of new or top customers."
        }
      />
    ),
    disableBeacon: true,
    placement: "bottom",
    route: "/admin/dashboard/business",
  },
  // Add more admin steps or additional routes as needed
];

export default getAdminSteps;
