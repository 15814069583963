// src/joyride/TourProvider.jsx
import React, { useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../context";
import useLocales from "../hooks/useLocales";

// STEP DEFINITIONS
import getUserSteps from "./steps-user";
import getAdminSteps from "./steps-admin";

export default function TourProvider() {
  const { translate } = useLocales();
  const { state, setState } = useAppContext();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { run, stepIndex, tourActive, tourType } = state;

  // Determine the steps based on tourType
  let steps = [];
  if (tourType === "admin") {
    steps = getAdminSteps(translate);
  } else if (tourType === "user") {
    steps = getUserSteps(translate);
  }

  // Current step object
  const currentStep = steps[stepIndex];

  // Joyride callback to handle step transitions, finishing, etc.
  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      // Tour finished or skipped
      setState({
        run: false,
        stepIndex: 0,
        tourActive: false,
        tourType: null, // Reset tour type
      });
      localStorage.setItem("hasSeenTour", "true");
      return;
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      let newIndex = index;

      if (action === ACTIONS.PREV) {
        newIndex = Math.max(0, index - 1);
      } else {
        newIndex = index + 1;
      }

      if (newIndex < steps.length) {
        const nextStep = steps[newIndex];
        if (nextStep.route && nextStep.route !== location.pathname) {
          // Navigate to the route of the next step
          navigate(nextStep.route);
          // Update stepIndex after navigation
          setState({ stepIndex: newIndex });
        } else {
          // Proceed to the next step on the same route
          setState({ stepIndex: newIndex });
        }
      }
    }
  };

  // Automatically start Joyride if `tourActive` is set
  useEffect(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 0 });
    }
  }, [tourActive, setState]);

  // Handle route changes to trigger Joyride for new steps
  useEffect(() => {
    if (tourActive && currentStep && currentStep.route === location.pathname) {
      // No action needed; Joyride will handle highlighting
    }
  }, [location.pathname, tourActive, stepIndex, steps, currentStep]);

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={stepIndex}
      continuous
      // scrollToFirstStep
      showSkipButton
      showBackButton
      // showProgress
      callback={handleJoyrideCallback}
      locale={{
        back: translate("Back") || "Back",
        close: translate("Close") || "Close",
        last: translate("Finish") || "Finish",
        next: translate("Next") || "Next",
        skip: translate("Skip") || "Skip",
      }}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: theme.palette.primary.main,
          textColor: theme.palette.text.primary,
          width: 400,
          arrowColor: theme.palette.background.paper,
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          fontFamily: theme.typography.fontFamily,
        },
        buttonNext: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        buttonBack: {
          color: theme.palette.primary.main,
        },
        buttonClose: {
          color: theme.palette.text.secondary,
        },
      }}
    />
  );
}
